import { Route } from '@angular/router';
import { LayoutComponent } from './layout/layout.component';
import { AuthGuard } from '@apruv-client/services';
import { ReviewComponent } from './review/review.component';
import { ReferralComponent } from './referral/referral.component';
import { TechnicianComponent } from './technician/technician.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { RoleGuard } from './shared/services/roleGuard.service';
import { VerifyComponent } from './verify/verify.component';
import { SalesRegistrationComponent } from './sales-registration/sales-registration.component';


export const appRoutes: Route[] = [
    {
        path: '',
        component: LayoutComponent,
        canActivate: [AuthGuard],
        loadChildren: () => import('./layout/layout.routes').then((c) => c.LAYOUT_ROUTES),
    },
    {
        path: 'review',
        component: ReviewComponent,
        loadChildren: () => import('./review/review.route').then((c) => c.REVIEW_ROUTES),
    },
    {
        path: 'referral',
        component: ReferralComponent,
        loadChildren: () => import('./referral/referral.route').then((c) => c.REFERRAL_ROUTES),
    },
    {
        path: 'verify',
        data: { breadcrumb: 'Verify', allowedRoles: ['verify_app']},
        canActivate: [RoleGuard],
        component: VerifyComponent,
        loadChildren: () => import('./verify/verify.route').then((c) => c.Verify_ROUTES),
    },
    {
        path: 'technician',
        component: TechnicianComponent,
        data: { breadcrumb: 'Technician', allowedRoles: ['technician_app']},
        canActivate: [RoleGuard],
        loadChildren: () => import('./technician/technician.routes').then((c) => c.TECHNICIAN_ROUTES),
    },
    {
        path: 'notifications',
        data: { breadcrumb: 'Technician', allowedRoles: ['technician_app']},
        canActivate: [RoleGuard],
        loadComponent: () => import('./technician/notifications/notifications.component').then((c) => c.NotificationsComponent),
    },
    {
        path: 'onboarding',
        component: OnboardingComponent,
        loadChildren: () => import('./onboarding/onboarding.routes').then((c) => c.ONBOARDING_ROUTES),
    },
    {
        path: 'registration/:company_id',
        component: SalesRegistrationComponent,
    },
    {
        path: 'access-denied',
        loadComponent: () => import('./accessdenied/accessdenied.component').then((c) => c.AccessDeniedComponent),
    },
];
