import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputMaskModule } from 'primeng/inputmask';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { AuthService, SalesRegistrationData } from '@apruv-client/services';
import { KeycloakService } from 'keycloak-angular';
import { Subscription } from 'rxjs';
import { DialogModule } from 'primeng/dialog';
import { FooterComponent } from '../technician/footer/footer.component';

export const FORM_STATUS_CONST = {
    valid: "VALID",
    invalid: "INVALID",
  };


@Component({
  selector: 'app-sales-registration',
  standalone: true,
  imports: [CommonModule, FormsModule, ToastModule, PasswordModule,
    ButtonModule,
    ReactiveFormsModule,
    InputTextModule,
    ButtonModule,
    ProgressSpinnerModule,
    InputMaskModule,
    NgxMaskDirective,
    NgxMaskPipe,
    DialogModule,
    FooterComponent
  ],
  templateUrl: './sales-registration.component.html',
  styleUrl: './sales-registration.component.scss',
})
export class SalesRegistrationComponent implements OnInit {
    errorMessage = "";
    isLoading = false;
    registrationForm!: FormGroup | any;
    test: any;
    company_id = ""
    formStatus = FORM_STATUS_CONST;
    invalidLinkVisible = false;
    registrationSubmitted = false;
    registrationError?: string = '';
    private subscription: Subscription = new Subscription();

    constructor(
      public authService: AuthService,
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private cdref: ChangeDetectorRef,
      private keycloak: KeycloakService
    ) {
      this.company_id = this.activatedRoute.snapshot.paramMap.get("company_id") || "";
      if(!this.company_id) this.invalidLinkVisible = true;
      console.log(this.company_id)
    }

    ngOnInit(): void {
      this.checkAuth();
      this.registrationForm = new FormGroup({
        first_name: new FormControl('', Validators.required),
        last_name: new FormControl('', Validators.required),
        phone_number: new FormControl('', Validators.required),
        registration_code: new FormControl("", Validators.required),
        encoded_company_id: new FormControl(this.company_id, Validators.required),
        email: new FormControl("", [
          Validators.required,
          Validators.pattern(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/),
        ]),
        password: new FormControl("", [Validators.required, Validators.minLength(8)]),
        password2: new FormControl("", [Validators.required, Validators.minLength(8)]),
      });
    }

    private async checkAuth() {
      const isLoggedInUser = await this.keycloak.isLoggedIn();
      if(isLoggedInUser) this.router.navigate(['/verify']);
    }

    onSubmit(): void {
      const {registration_code, email, password2, ...formData} = this.registrationForm.value;
      if (this.registrationForm.invalid || this.test) {
        return;
      }
      this.isLoading = true;
      this.errorMessage = "";
      this.subscription.add(this.registerSalesRep({registration_code, email, ...formData}))
    }

    private registerSalesRep(data: SalesRegistrationData): Subscription {
      return this.authService
        .registerSalesRep(data)
        .subscribe({
          next: (response) => {
            this.isLoading = false;
            this.registrationError = undefined;
            this.registrationSubmitted = true;
            setTimeout(() => {
              this.keycloak.login();
            }, 60000);
            this.cdref.markForCheck();
          },
          error: (e) => {
            this.registrationSubmitted = true;
            this.isLoading = false;
            this.registrationError = e?.error?.error;
            this.cdref.markForCheck();
          },
        });
    }

    goToLogin() {
      this.keycloak.login();
    }

    passwordsMatch(): void {
      const newPassword1 = this.registrationForm.get("password").value;
      const newPassword2 = this.registrationForm.get("password2").value;
      if (newPassword1 != newPassword2) {
        this.test = true;
      } else {
        this.test = false;
      }
    }
}
