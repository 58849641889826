<nav class="layout-breadcrumb text-lg text-gray-400">
    <ol>
        <ng-template ngFor let-item let-last="last" [ngForOf]="breadcrumbs$ | async">
            <li class="pd" [ngClass]="last ? 'surface-50 border-round-md' : ''">
                <a *ngIf="!last; else lastBreadcrumb" [routerLink]="item.url">{{ item.label }}</a>
                <ng-template #lastBreadcrumb>{{ item.label }}</ng-template>
            </li>
            <li *ngIf="!last" class="layout-breadcrumb-chevron pd">
                <i class="pi pi-chevron-right" style="font-size: 1rem;color: var(--text-color)"></i>
            </li>
        </ng-template>
    </ol>
</nav>
