import { ChangeDetectorRef, Component, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { animate, AnimationEvent, state, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MenuService } from './menu.service';
import { LayoutService } from '../service/layout-service.service';
import { SidebarComponent } from '../sidebar/sidebar.component';
import { CommonModule } from '@angular/common';
import { LayoutModule } from '../layout.module';
import { KeycloakService } from 'keycloak-angular';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[apruv-menuitem]',
  standalone: true,
  imports: [CommonModule, LayoutModule],
  template: `
        <ng-container>
          @if (root && item.visible !== false) {
            <div class="layout-menuitem-root-text">{{ item.label }}</div>
          }

          @if ((!item.routerLink && item.items) && item.visible !== false) {
            <a
                [attr.href]="item.url"
                (click)="itemClick($event)"
                (mouseenter)="onMouseEnter()"
                [ngClass]="item.class"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
                [pTooltip]="item.label"
                [tooltipDisabled]="!(isSlim && root && !active)"
            >
                <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                @if (item.items) {
                  <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                }
            </a>
          }
          @if ((!item.routerLink || item.items) && item.visible !== false) {
            <a
                [routerLink]="item.routerLink"
                [attr.href]="item.url"
                (click)="itemClick($event)"
                (mouseenter)="onMouseEnter()"
                [ngClass]="item.class"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
                [pTooltip]="item.label"
                [tooltipDisabled]="!(isSlim && root && !active)"
            >
                <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                @if (item.items) {
                  <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                }
            </a>
          }
          @if (item.routerLink && !item.items && item.visible !== false && !item.isSignOut) {
            @if (item.external) {
              <a
                (click)="externalItemClick()"
                (mouseenter)="onMouseEnter()"
                [ngClass]="item.class"
                routerLinkActive="active-route"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
                [pTooltip]="item.label"
                [tooltipDisabled]="!(isSlim && root)"
            >
                <span class="layout-menuitem-text">{{ item.label }}</span>
                <i class="pi pi-external-link ml-2"></i>
            </a>
            } @else {
              <a
                (click)="itemClick($event)"
                (mouseenter)="onMouseEnter()"
                [ngClass]="item.class"
                [routerLink]="item.routerLink"
                routerLinkActive="active-route"
                [routerLinkActiveOptions]="item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
                [fragment]="item.fragment"
                [queryParamsHandling]="item.queryParamsHandling"
                [preserveFragment]="item.preserveFragment"
                [skipLocationChange]="item.skipLocationChange"
                [replaceUrl]="item.replaceUrl"
                [state]="item.state"
                [queryParams]="item.queryParams"
                [attr.target]="item.target"
                tabindex="0"
                pRipple
                [pTooltip]="item.label"
                [tooltipDisabled]="!(isSlim && root)"
            >
                <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                <span class="layout-menuitem-text">{{ item.label }}</span>
                @if (item.items) {
                  <i class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                }
            </a>
            }
          }

          @if(item.isSignOut){
            <hr class="border-gray-600" style="width: 90%; margin: 1rem auto;">
              <a
                  (click)="signout()"
                  (mouseenter)="onMouseEnter()"
                  [ngClass]="item.class"
                  routerLinkActive="active-route"
                  [routerLinkActiveOptions]="item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
                  [attr.target]="item.target"
                  tabindex="0"
                  pRipple
                  [pTooltip]="item.label"
                  [tooltipDisabled]="!(isSlim && root)"
                  >
                  <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
                    <span class="layout-menuitem-text">{{ item.label }}</span>
                </a>
          }

          @if (item.items && item.visible !== false) {
            <ul [@children]="submenuAnimation" (@children.done)="onSubmenuAnimated($event)">
              <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
                  <li apruv-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
              </ng-template>
            </ul>
          }
        </ng-container>
    `,
  animations: [
    trigger('children', [
      state(
        'collapsed',
        style({
          height: '0'
        })
      ),
      state(
        'expanded',
        style({
          height: '*'
        })
      ),
      state(
        'hidden',
        style({
          display: 'none'
        })
      ),
      state(
        'visible',
        style({
          display: 'block'
        })
      ),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class MenuitemComponent implements OnInit, OnDestroy {
  @Input() item: any;

  @Input() index!: number;

  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;

  @Input() parentKey!: string;

  active = false;

  menuSourceSubscription: Subscription;

  menuResetSubscription: Subscription;

  key = '';

  constructor(public layoutService: LayoutService, private cd: ChangeDetectorRef, public router: Router, private Sidebar: SidebarComponent, private menuService: MenuService, private keycloak: KeycloakService) {
    this.menuSourceSubscription = this.menuService.menuSource$.subscribe((value) => {
      Promise.resolve(null).then(() => {
        if (value.routeEvent) {
          this.active = value.key === this.key || value.key.startsWith(this.key + '-') ? true : false;
        } else {
          if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
            this.active = false;
          }
        }
      });
    });

    this.menuResetSubscription = this.menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((params) => {
      if (this.isSlimPlus || this.isSlim || this.isHorizontal) {
        this.active = false;
      } else {
        if (this.item.routerLink) {
          this.updateActiveStateFromRoute();
        }
      }
    });
  }

  ngOnInit() {
    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);

    if (!(this.isSlimPlus || this.isSlim || this.isHorizontal) && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }
  }

  updateActiveStateFromRoute() {
    const activeRoute = this.router.isActive(this.item.routerLink[0], { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' });

    if (activeRoute) {
      this.menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    }
  }
  onSubmenuAnimated(event: AnimationEvent) {
    if (event.toState === 'visible' && this.layoutService.isDesktop() && (this.layoutService.isHorizontal() || this.layoutService.isSlim)) {
      const el = <HTMLUListElement>event.element;
      const container = <HTMLDivElement>this.Sidebar.menuContainer.nativeElement;

      if (this.layoutService.isHorizontal()) {
        el.style.removeProperty('top');
        const scrollLeft = container.scrollLeft;
        const offsetLeft = el.parentElement?.offsetLeft || 0;
        el.style.left = (offsetLeft - scrollLeft) + 'px';
      }
      else if (this.layoutService.isSlim() || this.layoutService.isSlimPlus()) {
        el.style.removeProperty('left');
        const scrollTop = container.scrollTop;
        const offsetTop = el.parentElement?.offsetTop || 0;
        el.style.top = (offsetTop - scrollTop) + 'px';
      }
    }
  }

  externalItemClick() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(this.item.routerLink)
    );
    console.log(url)
    window.open(`${url}/`, '_blank');
  }

  itemClick(event: Event) {
    // avoid processing disabled items
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    if(this.item.external) {
      event.preventDefault();
      const url = this.router.serializeUrl(
        this.router.createUrlTree(this.item.routerLink)
      );
    
      window.open(url, '_blank');

      return;
    }

    // navigate with hover
    if ((this.root && this.isSlim) || this.isHorizontal || this.isSlimPlus) {
      this.layoutService.state.menuHoverActive = !this.layoutService.state.menuHoverActive;
    }

    // execute command
    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    // toggle active state
    if (this.item.items) {
      this.active = !this.active;

      if (this.root && this.active && (this.isSlimPlus || this.isSlim || this.isHorizontal)) {
        this.layoutService.onOverlaySubmenuOpen();
      }
    } else {
      if (this.layoutService.isMobile()) {
        this.layoutService.state.staticMenuMobileActive = false;
      }

      if (this.isSlimPlus || this.isSlim || this.isHorizontal) {
        this.menuService.reset();
        this.layoutService.state.menuHoverActive = false;
      }
    }

    this.menuService.onMenuStateChange({ key: this.key });
  }

  onMouseEnter() {
    // activate item on hover
    if (this.root && (this.isSlimPlus || this.isSlim || this.isHorizontal) && this.layoutService.isDesktop()) {
      if (this.layoutService.state.menuHoverActive) {
        this.active = true;
        this.menuService.onMenuStateChange({ key: this.key });
      }
    }
  }

  get submenuAnimation() {
    if (this.layoutService.isDesktop() && (this.layoutService.isHorizontal() || this.layoutService.isSlim() || this.layoutService.isSlimPlus())) return this.active ? 'visible' : 'hidden';
    else return this.root ? 'expanded' : this.active ? 'expanded' : 'collapsed';
  }

  get isHorizontal() {
    return this.layoutService.isHorizontal();
  }

  get isSlim() {
    return this.layoutService.isSlim();
  }

  get isSlimPlus() {
    return this.layoutService.isSlimPlus();
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active && !this.root;
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }

  signout() {
    this.keycloak.logout();
    this.layoutService.isRedirect = false;
  }
}
